import { UpdateUserAccountRequest } from './../models/models_api_request/update-user-account-request';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../models/models_api/user-info';
import { Indicator } from '../models/indicator/indicator';
import { UserAuthorization } from '../models/userAuthorization';
import { EActionName } from '../models/Enums/eActionName';
import { CustomerAccount } from '../models/models_api/customerAccount';
import { ExternalUserWatcherInfo } from '../models/models_api/external-user-watcher-info';
import { EnvelopeWatcher } from '../models/models_api/envelope-watcher';
import { CreateUserRequest } from '../models/models_api_request/create-user-request';

@Injectable({
  providedIn: 'root'
})
export class ParametresService {

  static URL = environment.urlServer;

  static URL_Process = environment.processAPI;
  public UserInfos? : UserInfo;
  public CustomerAccount? : CustomerAccount;
  public ExternalUserEnvelopeWatcher? : EnvelopeWatcher;

  public ForbiddenActionMessage = "Vous ne pouvez pas réaliser cette action.\nVeuillez contacter votre administrateur E-SIGNE pour plus de détails.";
  public ForbiddenPageMessage = "Vous n’avez pas le droit d’afficher cette page.\nVeuillez contacter votre administrateur E-SIGNE pour plus de détails.";
  public ForbiddenInformationMessage = "Vous ne pouvez pas voir ces informations.\nVeuillez contacter votre administrateur E-SIGNE pour plus de détails.";
  /** Boolean afin de savoir si on est en mode vue embarqué. */
  public IsEmbededVue: boolean = false;

  private headers = new HttpHeaders({
    'accept': 'text/plain',
    'Content-Type': 'application/json', 
    'Authorization': `bearer ${localStorage.getItem("Token")}`,
  });

  private userAuthorizationKvp:any = [];

  constructor(private http: HttpClient) {
    this.IsEmbededVue = new URL(window.location.href).searchParams.get('embeded') == 'true';
  }

  public refreshHeader(){
    this. headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json', 
      'Authorization': `bearer ${localStorage.getItem("Token")}`,
    });
  }

  public getList_Users_Parameters(): Observable<any> {
      
    return this.http
      .get(ParametresService.URL + 'UserAccounts', { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

  public getList_Users_Parameters_With_CustomerAccount(id_Customer_Account: string): Observable<any> {
      
    return this.http
      .get(ParametresService.URL + 'UserAccounts?Id_Customer_Account=' + id_Customer_Account, { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }


  public transfertEnvelopeSignature(ID_Envelope_Signature:string,target_user_id:string, targetLaunchedBy:boolean, targetManagedBy:boolean): Observable<any> {
      var req = {
        "launchedBy": targetLaunchedBy,
        "managedBy": targetManagedBy,
        "iD_Envelope_Signature": ID_Envelope_Signature,
        "target_User_ID": target_user_id
      }
    return this.http
      .post(ParametresService.URL + 'Envelopes/TransfertSignature',req, { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

  public getList_Society_Parameters(): Observable<any> {
      
    return this.http
      .get(ParametresService.URL + 'CustomerAccounts', { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

  public getUser_Info(): Observable<any> {
      
    return this.http
      .get(ParametresService.URL + 'UserAccounts/UserInfos', { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
        this.UserInfos = response.body;
          return response.body;
        })
      );
  }

  public getExternal_WatcherInfo(): Observable<any> {
      
    return this.http
      .get(ParametresService.URL + 'UserAccounts/WatcherInfos', { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
        this.UserInfos = response.body;
          return response.body;
        })
      );
  }

  public loadUserInfo(): Observable<any> {
      
    return this.http
      .get(ParametresService.URL + 'UserAccounts/UserInfos', { headers: this.headers, observe: 'response' })  
      .pipe(
        tap((resp:any) => { this.UserInfos = resp.body}),
        concatMap(() => this.http.get(ParametresService.URL + 'UserAccounts/GetAuthorizations', { headers: this.headers, observe: 'response' })  ),
        tap((authResp:any) => this.setUserAuthorizations(authResp.body))
      );
  }

  public getUser_Authorizations(): Observable<any> {
      
    return this.http
      .get(ParametresService.URL + 'UserAccounts/GetAuthorizations', { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          this.setUserAuthorizations(response.body);
          return response.body;
        })
      );
  }

  public getUser_Parameters(iD_User_Account: string): Observable<any> {
      
    return this.http
      .get(ParametresService.URL + 'UserAccounts/' + iD_User_Account + '/UserInfos', { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
        if(!environment.production){
          localStorage.setItem("CurrentUserParameter", JSON.stringify( response.body))
        }
          return response.body;
        })
      );
  }

  public getUser_ApplyAccessRole(iD_User_Account: string): Observable<any> {
      
    return this.http
      .get(ParametresService.URL + 'UserAccounts/' + iD_User_Account + '/Roles', { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

  public getAccessRoleActions(iD_Access_Role:string){
    return this.http
      .get(`${ParametresService.URL}UserAccounts/GetAccessRoleActions/${iD_Access_Role}`, { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

  public putUser_Params(iD_User_Account: string, requestUpdate: UpdateUserAccountRequest): Observable<any> {
      
    return this.http
      .put(ParametresService.URL + 'UserAccounts/' + iD_User_Account, JSON.stringify(requestUpdate), { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

  public putStatusCode_UserParams(iD_User_Account: string, status_code: string): Observable<any> {
      
    return this.http
      .put(ParametresService.URL + 'UserAccounts/' + iD_User_Account + '/SetStatusCode', JSON.stringify({ "status_code": status_code }), { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

public getCustomerIndicators(startDate? : Date, endDate? : Date): Observable<Array<Indicator>> {
  return this.http
  .post(`${ParametresService.URL}UserAccounts/CustomerAccountIndicators`,JSON.stringify({startDate : startDate, endDate: endDate}), { headers: this.headers, observe: 'response' })  
  .pipe(map((response: HttpResponse<any>) => {
      return response.body as Array<Indicator>;
    })
  );
}

public getUserIndicators(startDate? : Date, endDate? : Date): Observable<Array<Indicator>> {
  return this.http
  .post(`${ParametresService.URL}UserAccounts/UserAccountIndicators`,JSON.stringify({startDate : startDate, endDate: endDate}), { headers: this.headers, observe: 'response' })  
  .pipe(map((response: HttpResponse<any>) => {
      return response.body as Array<Indicator>;
    })
  );
}

public changeUserPinCode(pinCodeValue:string): Observable<HttpResponse<any>> {
  return this.http
  .patch(`${ParametresService.URL}Login/ChangePinCode`,JSON.stringify({value : pinCodeValue}), { headers: this.headers, observe: 'response' })  
  .pipe(map((response: HttpResponse<any>) => {
      return response;
    })
  );
}

/** Instancie à l'objet utilisateur les autorisations auquel il a droit */
private setUserAuthorizations(authResponse: Array<any>){

  if (this.UserInfos) {
    if (!this.UserInfos.UserAuthorizations) 
    this.UserInfos.UserAuthorizations = new Array<UserAuthorization>();
    authResponse.forEach(auth =>{
      this.userAuthorizationKvp[auth.Technical_Name] = auth.Authorized == 1;
      this.UserInfos?.UserAuthorizations?.push({Authorized : auth.Authorized, Technical_Name : auth.Technical_Name});
    })
  }
  
  console.log(this.UserInfos);
}

public changeCustomerAccount(iD_Customer_account:string): Observable<HttpResponse<any>> {
  return this.http
  .post(`${ParametresService.URL}UserAccounts/ChangeCustomerAccount`,JSON.stringify({ID_Customer_Account : iD_Customer_account}), { headers: this.headers, observe: 'response' })  
  .pipe(map((response: HttpResponse<any>) => {
      return response;
    })
  );
}

public getCustomerAccounts(): Observable<HttpResponse<any>> {
  return this.http
  .get(`${ParametresService.URL}UserAccounts/GetCustomerAccounts`, { headers: this.headers, observe: 'response' })  
  .pipe(map((response: HttpResponse<any>) => {
      return response;
    })
  );
}

public getCustomerAccount(): Observable<CustomerAccount> {
  return this.http
    .get(ParametresService.URL + 'CustomerAccounts/GetCustomerAccount', { headers: this.headers, observe: 'response' })  
    .pipe(map((response: HttpResponse<any>) => {
        this.CustomerAccount = response.body;
        return response.body;
      })
    );
}

public getLogConnection(): Observable<any> {
  return this.http
    .get(ParametresService.URL + 'UserAccounts/GetLogConnection', { headers: this.headers, observe: 'response' })  
    .pipe(map((response: HttpResponse<any>) => {
        return response.body;
      })
    );
}

public duplicateExistingTemplate(id_template_document:string): Observable<any> {
  return this.http
    .get(`${ParametresService.URL}DocumentTemplates/DuplicateExistingTemplate/${id_template_document}`, { headers: this.headers, observe: 'response' })  
    .pipe(map((response: HttpResponse<any>) => {
        return response;
      })
    );
}

public getBackupsByDocumentModelId(id_template_document:string){
  return this.http
  .get(`${ParametresService.URL}DocumentTemplates/GetBackups/${id_template_document}`, { headers: this.headers, observe: 'response' })  
  .pipe(map((response: HttpResponse<any>) => {
      return response.body;
    })
  );
}

public getAvailableBackupsEnvironment(){
  return this.http
  .get(`${ParametresService.URL}DocumentTemplates/GetAvailableExportEnvironments`, { headers: this.headers, observe: 'response' })  
  .pipe(map((response: HttpResponse<any>) => {
      return response.body;
    })
  );
}

public saveModelAsBackup(id_template_document:string, backupName?:string){
  var optionalParam = backupName ? `?BackupName=${backupName}` : ''
  return this.http
  .get(`${ParametresService.URL}DocumentTemplates/SaveGabaritAndModelAsBackup/${id_template_document}${optionalParam}`, { headers: this.headers, observe: 'response' })  
  .pipe(map((response: HttpResponse<any>) => {
      return response;
    })
  );
}

public restoreModelFromBackup(id_template_backup:string){
    var req = {
      token: "",
      targetEnvironmentName: "",
      iD_Template_Backup: id_template_backup
    };
  return this.http
  .post(`${ParametresService.URL}DocumentTemplates/ImportExistingTemplate`,req, { headers: this.headers, observe: 'response' })  
  .pipe(map((response: HttpResponse<any>) => {
      return response;
    })
  );
}

public exportModelFromBackup(id_template_backup:string, environmentName:string, token:string){
  var req = {
    token: token,
    targetEnvironmentName: environmentName,
    iD_Template_Backup: id_template_backup
  };
return this.http
.post(`${ParametresService.URL}DocumentTemplates/ExportExistingTemplate`,req, { headers: this.headers, observe: 'response' })  
.pipe(map((response: HttpResponse<any>) => {
    return response;
  })
);
}

/** Méthode afin de déterminer si l'utilisateur peut effectuer l'action passé en parametre. 
 * @actionName : Enum de l'action concerné.
*/
public isUserCan(actionName:EActionName):boolean{
  // var result = this.UserInfos?.UserAuthorizations?.find(x => x.Technical_Name == actionName && x.Authorized);
  // return result != null;
  var result = this.userAuthorizationKvp[actionName];
  return result == true;
}

public createUser(request:CreateUserRequest): Observable<any> {
  return this.http
    .post(ParametresService.URL + 'UserAccounts/CreateUser',request, { headers: this.headers, observe: 'response' })  
    .pipe(map((response: HttpResponse<any>) => {
        return response;
      })
    );
}

}
