<div class="position-relative w-100 h-100">
    <div class="position-absolute top-50 start-50 translate-middle w-90">

        <div class="d-flex justify-content-center ms-2 me-2">
            <div class="bg-white rounded shadow-lg">
<!-- minw-800px -->
                <div *ngIf="!_isPinToInitialize" class="d-flex flex-row p-5 minh-300px"> <!-- SECURITY PB PAGE | PIN CODE TO LOGIN | EXPIRED MAGIC LINK -->
                    <div class="w-auto d-flex flex-column">
                        <img src="../../../../assets/logoSignPro.png" class="img-thumbnail border-0 imgSignPro" alt="...">

                        <!-- SECURITY SECTION -->
                        <div *ngIf="_isForbidden" class="fs-4 pt-3">
                            <div class="d-flex align-items-center">
                                <img src="../../../../assets/shieldSecurity.svg" class="img-thumbnail border-0 rounded"
                                    alt="...">
                                <div class="text-center align-middle"> <span class="m-auto p-auto fw-bold"> Problème de
                                        sécurité
                                    </span></div>
                            </div>
                            <br>
                            <div class="text-break">
                                Nous avons détecté un problème de sécurité.
                                Le contexte d’utilisation du lien magique n’est pas conforme à l’usage ou votre demande
                                de connexion
                                a expiré.​
                            </div>
                            <button type="button" class="btn btn-secondary mt-5 fw-bold" [routerLink]="['/']">
                                < SE RECONNECTER </button>
                        </div>
                        <!-- END SECURITY SECTION -->

                        <!-- UNAUTH SECTION -->
                        <div *ngIf="_isUnauthorised || _hasError" class="fs-4 pt-3 w-auto">
                            <div class="d-flex align-items-center">
                                <img src="../../../../assets/unplug.svg" class="img-thumbnail border-0 rounded"
                                    alt="...">
                                <div class="text-center align-middle"> <span class="m-auto p-auto fw-bold"> Lien expiré
                                    </span></div>
                            </div>
                            <br>
                            <div class="text-break"> Cette demande de connexion a expiré.
                            </div>
                            <button type="button" class="btn btn-secondary mt-5 fw-bold" [routerLink]="['/']">
                                < SE RECONNECTER </button>
                        </div>
                        <!-- END UNAUTH SECTION -->

                        <!-- TYPING PINCODE SECTION -->
                        <div *ngIf="_isTypingPinCode" class="fs-4">
                            <div> Veuillez saisir votre code PIN de sécurité à 6 chiffres </div>
                            <br>

                            <app-pin-code #pinCode
                                (pinCodeValue)="onPinCodeValueChanged($event)" >
                            </app-pin-code>

                            <div *ngIf="_isSendingPinCode" class="d-flex flex-row-reverse align-items-center fs-6 mt-1 text-secondary">
                                Connexion en cours...<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            </div>

                            <div *ngIf="_isPinCodeError" class="d-flex flex-row-reverse align-items-center fs-6 mt-1 text-danger">
                                Code pin erroné <span *ngIf="_pinAttempt == 2" class="badge rounded-pill text-bg-danger me-1">{{ this._pinAttempt}}</span>
                            </div>
                        </div>
                        <!-- END TYPING PINCODE SECTION -->


                        <!-- 3 ATTEMPT PINCODE INPUTS SECTION -->
                        <div *ngIf="_pinAttempt >= 3" class="fs-4 pt-3 w-auto">
                            <div class="d-flex align-items-center">
                                <img src="../../../../assets/deletebutton-md.png" class="img-thumbnail border-0 rounded"
                                    alt="...">
                                <div class="text-center align-middle"> <span class="m-auto p-auto fw-bold text-danger"> Lien de connexion désactivé
                                    </span></div>
                            </div>
                            <br>
                            <div class="text-break"> Le lien de connexion a été désactivé au bout de 3 tentatives infructueuses.
                            </div>
                            <button type="button" class="btn btn-secondary mt-5 fw-bold" [routerLink]="['/']">
                                < SE RECONNECTER </button>
                        </div>
                        <!-- END 3 ATTEMPT PINCODE INPUTS SECTION -->
                    </div>

                    <div class="w-60 d-flex flex-column justify-content-center align-items-center hiddenMobile">
                        <img src="../../../../assets/pen.jpg"
                            class="" alt="...">
                    </div>
                </div>



                <div *ngIf="_isPinToInitialize" class="row container p-5 minh-300px minw-800px"> <!-- PIN CODE TO INITIALIZE -->
                    <div>
                        <img src="../../../../assets/logoSignPro.png" class="img-thumbnail border-0" alt="...">
                    </div>
                    
                    <div class="col-6">
                        <div *ngIf="_isTypingPinCode" class="fs-4">
                            <p class="fs-5"> 
                                Veuillez saisir votre code PIN de sécurité à 6 chiffres 
                            </p>
                            <br>

                            <app-pin-code #initPinCode1
                                (pinCodeValue)="onInitializingPinCode1($event)">
                            </app-pin-code>
                        </div>
                    </div>

                    <div class="col-6">

                        <div *ngIf="_isTypingPinCode" class="fs-4">
                            <p class="fs-5"> 
                                Veuillez confirmer votre code PIN de sécurité à 6 chiffres
                            </p>
                            <br>

                            <app-pin-code #initPinCode2
                            (pinCodeValue)="onInitializingPinCode2($event)">

                            </app-pin-code>
                        </div>

                    </div>
                    <div class="d-flex flex-row-reverse align-items-center pt-3">
                        <button (click)="onSendPinCodeButtonClicked()" type="button"
                        [ngClass]="{'disabled': _isSendingPinCode || !_isPinCodesEqualsToInitialize}"
                        class="btn btn-secondary mt-1 fw-bold">
                        <span *ngIf="_isSendingPinCode" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        CONNEXION
                    </button>
                    <span *ngIf="_pinCode2.length == 6 && _pinCode2.length == 6 && _isPinCodesEqualsToInitialize == false" class="fs-6 mt-1 text-danger me-5">Les codes pin ne correspondent pas.</span>
                    </div>

                </div>

            </div>





        </div>

    </div>
</div>