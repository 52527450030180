import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  //#region Fields
  private _headers = new HttpHeaders({
    'accept': 'text/plain',
    'Content-Type': 'application/json',
    'Authorization': `bearer ${localStorage.getItem("Token")}`,
  });
  //#endregion

  public hasConnectionChecked : boolean = false;


  /**
   * Valide un lien magique.
   * @param magicLinkGuid Guid du lien magique
   * @returns Retourne un HTTP 400 si le context de l'utilisateur est différent par rapport à la demande. (IP Address, User-Agent or browser)
   */
  public validateMagicLink(magicLinkGuid: string) {

    return this.http.post(`${environment.urlServer}Login/ValidateMagicLink`, JSON.stringify({ Id: magicLinkGuid }), { headers: this._headers, observe: 'response' })
  }

  /**
   * Demande un lien magique si, et seulement si, l'utilsiateur est connu du système d'information.
   * @param email Email de l'utilisateur
   * @returns Retourne toujours un HTTP 200
   */


  public askMagicLink(email: string) {
    return this.http.get(`${environment.urlServer}Login/CheckEmail/${email}`, { headers: this._headers, observe: 'response' })
  }


  public validatePinCode(magicLinkGuid: string, pinCodes: Array<string>) {
    return this.http.post(`${environment.urlServer}Login/ValidatePinCode`, JSON.stringify(
      {
        MagicLinkGuid: magicLinkGuid,
        PinCodes: pinCodes
      }
    ), { headers: this._headers, observe: 'response' })
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    return of(err);
}

  public isUserConnected() {
    this._headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json',
      'Authorization': `bearer ${localStorage.getItem("Token")}`,
    });
    return this.http
    .get(`${environment.urlServer}Login/IsConnected`, { headers: this._headers, observe: 'response' })
    .pipe(
      map((resp : HttpResponse<any>) => {
      return resp;
    }),
    catchError( (x : HttpErrorResponse) => { 
      return (x.ok, this.handleAuthError(x));
    })
    );
  }

}
