import { 
  Component, 
  OnInit, 
  Output,
  Input,
  ViewChild,
  ElementRef, 
  EventEmitter} from '@angular/core';

@Component({
  selector: 'app-pin-code',
  templateUrl: './pin-code.component.html',
  styleUrls: ['./pin-code.component.scss']
})
export class PinCodeComponent implements OnInit {

  @Output() pinCodeValue = new EventEmitter<string>();
  @Input() SetPinCode = (value:string) =>{ this.applyTextRestriction(value)};
  _btnTheme: string = 'btn-secondary opacity-75';
  _randonIntegerArray: Array<number> = []
  _numericalPad: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

  _hidePassword: boolean = true;
  _value: string = '';
  _disablePinForm: boolean = false;

  @ViewChild('pin') bindingInput!: ElementRef;
  constructor() { }

  ngOnInit(): void {
    this.initializeNumericalPad()
  }


  private initializeNumericalPad() {
    var numPadLength = this._numericalPad.length - 1;
    for (let i = 0; i < numPadLength; i++) {
      var randomNbSelected = Math.floor(Math.random() * this._numericalPad.length);
      this._randonIntegerArray.push(
        this._numericalPad[randomNbSelected]
      );
      this._numericalPad.splice(randomNbSelected, 1);
    }
  }

  public onTypingNumericalPad(event: any) {
    var text = this._value += event.target.innerText;
    this.applyTextRestriction(text);
  }

  public onDeleteValue() {
    this._value = this._value.slice(0, -1);
    this.applyTextRestriction(this._value);
  }

  public onResetValue() {
    this._value = '';
    this.applyTextRestriction(this._value);
  }

  public onShowPassword() {
    this._hidePassword = !this._hidePassword
  }


  public onTypingPinCode(value: any) {
    this.applyTextRestriction(value);
    this.bindingInput.nativeElement.value = this._value;
  }



  private applyTextRestriction(value:string){
    this._value = value.replace(/\D/g, "").slice(0,6);
    this.pinCodeValue.emit(this._value)
  }



}
