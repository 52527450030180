import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-external-authorization',
  templateUrl: './external-authorization.component.html',
  styleUrls: ['./external-authorization.component.scss']
})
export class ExternalAuthorizationComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const urlParams = new URLSearchParams(window.location.search);
      const idEnvelope = urlParams.get('ID_Envelope_Signature');
      const routeToRedirect = urlParams.get('Route_To_Redirect');
      const isEmbeded = urlParams.get('embeded') == 'true';
      var token = urlParams.get('Token');

      if(idEnvelope && token){
        localStorage.setItem("Token", token);
        this.router.navigate([`/log/signature/${idEnvelope}`]);
      }
      else if(routeToRedirect && token){
        localStorage.setItem("Token", token);
        if(isEmbeded)
          this.router.navigate([routeToRedirect],{ queryParams: { embeded: 'true' } });
        else this.router.navigate([routeToRedirect]);

      }

    })
  }

}
