import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { concatMap, map, Subject, takeUntil, tap } from 'rxjs';
import { UserInfo } from '../shared/models/models_api/user-info';
import { AuthService } from '../shared/services/auth.service';
import { ParametresService } from '../shared/services/parametres.service';
import { ExternalUserWatcherInfo } from '../shared/models/models_api/external-user-watcher-info';
import { EnvelopeWatcher } from '../shared/models/models_api/envelope-watcher';


@Injectable({ providedIn: 'root' })
export class ExternalAuthGuard implements CanActivate {
  private ngUnsubscribe = new Subject();

    constructor(private _router: Router, private authService: AuthService, private paramService : ParametresService,private route: ActivatedRoute) {
        var t = new URL(window.location.href).searchParams.get('external_t');
        if(t){
            localStorage.setItem("Token", t);
            paramService.refreshHeader();
        }
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        try{
            var IsConnected:boolean = false;
            return this.authService.isUserConnected().pipe(
            tap((resp1:HttpResponse<any>) =>{
                IsConnected = resp1.ok;
                if(!IsConnected){
                    localStorage.setItem("requestedUrl",window.location.href);
                    window.location.href;
                    IsConnected = false;
                    this._router.navigate(['']);
                    localStorage.removeItem("Token");
                }
            }),
            concatMap(resp2 => this.paramService.getExternal_WatcherInfo()),
            tap({
                next:(resp2:EnvelopeWatcher) =>{
                    this.paramService.ExternalUserEnvelopeWatcher = resp2; 
                    console.log("next")},   
                error:(err)=>{
                    return IsConnected;
                }}),
            // concatMap(resp3 => this.paramService.getUser_Authorizations()),
            // tap(() =>{})
            ).pipe(takeUntil(this.ngUnsubscribe),map((resp:any)=>{
                if (!IsConnected) {
                    this._router.navigate(['']);
                    localStorage.removeItem("Token");
                }
                return IsConnected;
            }))

        }catch(ex){
            console.log("Erreur")
            this._router.navigate(['']);
            localStorage.removeItem("Token");
            return false
        }

        
      //   try
      //   {
      //     return this.authService.isUserConnected().pipe(takeUntil(this.ngUnsubscribe), map((resp: any ) =>{
      //       var tokenValue = localStorage.getItem('Token');
      //       if(!tokenValue){
      //         this._router.navigate(['']);
      //         localStorage.removeItem("Token");
      //         return false;
      //       }
      //       else {
      //         if(!resp.ok)
      //         {
      //             this._router.navigate(['']);
      //             localStorage.removeItem("Token");
      //         }
      //         return resp.ok;
      //       }
      //     }));
      // }catch(ex){
      //     this._router.navigate(['']);
      //     localStorage.removeItem("Token");
      //     return false
      // }
    }
}